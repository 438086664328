<template>
  <div class="m1">
    <div class="hd">
      <div class="h1">
        管理账号
      </div>
      <div class="h2" @click="dialogFormVisible = true">
        新增账号
      </div>
    </div>

    <!-- 列表 -->
    <div class="lb">

      <div class="hd" v-for="(item, index) in Data" :key="index" :class="index % 2 == 0 ? 'bg1' : 'bg2'">
        <!-- 账号密码 -->
        <div>
          <span class="name">
            姓名：{{ item.name }}
          </span>
          <span class="zh">
            账号：{{ item.account }}
          </span>
          <span class="zh">
            密码：******
          </span>
        </div>

        <!-- 权限 -->
        <div class="qx">
          账号权限：
          <span class="lst" v-for="(item1, index1) in item.permissionList" :key="index1"
            :class="item1.isSelect ? 'b_s' : 'b_h'" @click="Qx(item, index, index1)">
            {{ item1.name }}
          </span>
        </div>

        <!-- 修改 -->
        <div class="xg">
          是否启用：
          <span>
            <el-switch style="height: 1rem; width: 6rem;" v-model="item.KG" @change="change(item)"
              active-color="rgba(28, 184, 184, 1)" inactive-color="rgba(212, 214, 217, 0.6)">
            </el-switch>
          </span>
          <span class="gg" @click="open(item)">更改密码</span>
          <span class="gg" style="width: 4rem; background-color: red;" @click="del(item.id)">
            删除
          </span>
        </div>
      </div>

    </div>

    <!-- 翻页 -->
    <div style="width: 100%; height: 5rem; padding: 1rem 0; margin-top: 4rem;">
      <!-- <span class="demonstration">完整功能</span> -->
      <el-pagination style="float: right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>

    <!-- 新增弹窗 -->
    <el-dialog title="新增账号" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="姓名:" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="账号:" :label-width="formLabelWidth">
          <el-input v-model="form.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码:" :label-width="formLabelWidth">
          <el-input v-model="form.password" autocomplete="off"></el-input>
        </el-form-item>
        <div class="qx">
          <div style="margin-bottom: 2rem;">
            账号权限：
          </div>
          <span class="lst" style="margin-right: 1rem; border-radius: .5rem;" v-for="(item, index) in list" :key="index"
            :class="item.isSelect ? 'b_s' : 'b_h'" @click="Qx1(item)">
            {{ item.name }}
          </span>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="Form()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'gsgly',
  data() {
    return {
      checkboxGroup2: ['在线检测'],
      list: [{
        "id": 2,
        "name": "项目管理",
        "isSelect": false
      },
      {
        "id": 3,
        "name": "设备管理",
        "isSelect": false
      },
      {
        "id": 4,
        "name": "施工管理",
        "isSelect": false
      },
      {
        "id": 5,
        "name": "告警管理",
        "isSelect": false
      },
      {
        "id": 6,
        "name": "账号管理",
        "isSelect": false
      },
      {
        "id": 1,
        "name": "在线监测",
        "isSelect": false
      },
      {
        "id": 7,
        "name": "数据库备份",
        "isSelect": false
      },
      {
        "id": 8,
        "name": "入库管理",
        "isSelect": false
      }
      ],
      value: true,
      currentPage4: 1,
      obj: {
        "page": 1,
        "size": 10
      },
      Data: [],
      total: null,
      dialogFormVisible: false,
      form: {
        name: '',
        account: '',
        password: '',
        permissionIds: []
      },
      formLabelWidth: '12rem'
    }
  },
  created() {
    this.info()
  },
  methods: {
    Form() {
      this.$ajax('addgly', 'post', this.form).then(res => {
        console.log(res);
        if (res.code == 2000) {
          this.info()
          this.form = {
            name: '',
            account: '',
            password: '',
            permissionIds: []
          }
          this.list.forEach(item => {
            item.isSelect = false
          })
          this.dialogFormVisible = false
          this.$message({
            type: 'success',
            message: res.message
          });
        } else {
          this.$message({
            type: 'error',
            message: res.message
          });
        }
      })
    },
    Qx1(item) {
      item.isSelect = !item.isSelect
      this.form.permissionIds = []
      this.list.forEach(item => {
        if (item.isSelect) {
          this.form.permissionIds.push(item.id)
        }
      })
      console.log(this.form.permissionIds, 'this.form.type!!!');
    },
    XZ(e) {
      console.log(e);
    },
    Qx(item, num, id) {
      item.permissionList[id].isSelect = !item.permissionList[id].isSelect
      // console.log(this.Data[num].permissionList[id]);
      console.log(item);
      let newarr = []
      item.permissionList.forEach((item1, index1) => {
        if (item1.isSelect) {
          newarr.push(item1.id)
        }
      })
      console.log(newarr);
      let obj = {
        "id": item.id,
        "permissionIds": newarr
      }
      this.$ajax('qxgly_1', 'post', obj).then(res => {
        console.log(res);
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: res.message
          });
        } else {
          this.$message({
            type: 'error',
            message: res.message
          });
        }
      })
    },
    change(item) {
      let state = null
      if (item.KG) {
        state = 0
      } else {
        state = 1
      }
      let obj = {
        "id": item.id,
        "state": state
      }
      console.log(obj);

      this.$ajax('qxgly', 'post', obj).then(res => {
        console.log(res);
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: res.message
          });
        } else {
          this.$message({
            type: 'error',
            message: res.message
          });
        }
      })
    },
    open(item) {
      this.$prompt('请输入' + item.account + '新密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({
        value
      }) => {
        let obj = {
          "id": item.id,
          "password": value
        }
        console.log(value, 'updgly!!!');
        this.$ajax('updgly', 'post', obj).then(res => {
          console.log(res);
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: res.message
            });
          } else {
            this.$message({
              type: 'error',
              message: res.message
            });
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    del(id) {
      this.$confirm('是否删除此账号?', '提示', {
        confirmButtonText: '取消',
        cancelButtonText: '确定',
        type: 'warning',
        showClose: false
      }).then(() => {
        this.$ajax('delgsgly', 'delete', id).then(res => {
          this.info()
          this.$message({
            type: 'success',
            message: '删除成功'
          });
        }).catch(err => {
          this.$message({
            type: 'error',
            message: err.message
          });
        })

      }).catch(() => {
        this.$message({
          message: '取消删除',
          type: 'warning'
        });
      })

  },
  handleSizeChange(val) {
    console.log(`每页 ${val} 条`);
    this.obj.size = val
    this.info()

  },
  handleCurrentChange(val) {
    console.log(`当前页: ${val}`);
    this.obj.page = val
    this.info()
  },
  info() {
    this.$ajax('gsgly', 'post', this.obj).then(res => {
      console.log(res);
      this.Data = res.data.records
      this.total = res.data.total
      this.Data.forEach(item => {
        if (item.state == 0) {
          this.$set(item, 'KG', true)
        } else {
          this.$set(item, 'KG', false)
        }
      })
    })
  }
},
  }
</script>

<style lang="scss" scoped>
.bg {
  background-color: rgba(43, 201, 222, 0.6);
}

.bg1 {
  background-color: rgba(162, 162, 162, 0.2);
}

.bg2 {
  background: rgba(14, 242, 242, 0.3);
}

.b_s {
  background-color: rgba(43, 201, 222, 0.6);
}

.b_h {
  background-color: rgba(212, 214, 217, 0.6);
}

.m1 {
  position: relative;
  color: #FFFFFF;
  margin: 1rem 0 0 .5rem;
  width: 156.6rem;
  // height: 59.4rem;

  .hd {
    position: relative;
    width: 100%;
    height: 3.6rem;
    // padding: rem 0;
    // overflow: hidden;


    .h1 {
      display: inline-block;
      width: 11.5rem;
      height: 5rem;
      line-height: 5rem;
      padding-left: 1rem;
      font-size: 1.8rem;
    }

    .h2 {
      position: absolute;
      top: 1rem;
      right: 2rem;
      width: 11.5rem;
      height: 4.2rem;
      background-color: rgba(43, 201, 222, 1);
      line-height: 4.2rem;
      text-align: center;
      border-radius: .5rem;
      cursor: pointer;
    }
  }

  .lb {
    position: relative;
    // border: 1px solid #2BDEC9;
    margin-top: 4rem;
    font-size: 1.4rem;
    // overflow: auto;

    .hd {
      width: 100%;
      height: 3.6rem;
      line-height: 3.6rem;
      // padding-left: 1rem;
      font-size: 1.4rem;
      // border: 1px solid #2BDEC9;
      border-bottom: none;
      box-sizing: border-box;

      .zh {
        margin-left: 1rem;
        display: inline-block;
        width: 16.8rem;
        overflow: hidden;
      }

      .name {
        margin-left: 1rem;
        display: inline-block;
        width: 10.8rem;
        overflow: hidden;
      }
    }

    .hd:last-child {
      border-bottom: none;
    }

    .hd:last-child {
      // border-bottom: 1px solid #2BDEC9;
    }

    .qx {
      position: absolute;
      top: 0rem;
      left: 50rem;
      display: inline-block;

      .lst {
        cursor: pointer;
        display: inline-block;
        width: 7rem;
        height: 2.6rem;
        line-height: 2.6rem;
        // background-color: rgba(43, 201, 222, 0.6);
        text-align: center;
        border-radius: .3rem;
        margin-right: 1rem;
      }
    }

    .xg {
      position: absolute;
      top: .5rem;
      right: 2rem;
      width: 27rem;
      height: 2.6rem;
      line-height: 2.6rem;

      .gg {
        cursor: pointer;
        display: inline-block;
        width: 6.6rem;
        height: 2.6rem;
        line-height: 2.6rem;
        text-align: center;
        background-color: rgba(28, 184, 184, 1);
        border-radius: .4rem;
        margin-left: 1rem;
      }
    }
  }
}
</style>
